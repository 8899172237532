import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const isBrowser = typeof window !== "undefined";

if(isBrowser){
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementById("header").className = "small";
    } else {
      document.getElementById("header").className = "big";
    }
  }  
}

class Header extends React.Component 
{
  constructor(props){
    super(props);
    this.state = {isOpen: false};

    this.toggle = this.toggle.bind(this);
  }
  toggle(){
    this.setState({
      isOpen : !this.state.isOpen
    });
  }

  render()
  {
  return    (
  <header id="header" className="big">
      <div className="logo" id="logo">
        <Link
          to="/">
            <img src="/images/logo-back-blue.png" style={{verticalAlign: 'middle', margin: 0}} alt="conetist logo" />
          Conetist
        </Link>
      </div>
      <div className="burger" onClick={this.toggle}>
          <img src="/images/burger.png" alt="burger menu" />
      </div>      
      <div className={this.state.isOpen ? 'menu open' : 'menu close'}>
        {(this.props.type === 'site') && 
        <>
        <Link className="menuitem" onClick={this.toggle}
          to="/editorial/">
            éditorial
        </Link>
        <a
          href="http://blog.conetist.com" className="menuitem">
          blog
        </a>
        </>
        }
        {(this.props.type === 'blog') && 
        <>
        <a className="menuitem" 
          href="http://www.conetist.com/editorial/">
            éditorial
        </a>
        <a
          href="http://www.conetist.com" className="menuitem">
          application
        </a>
        </>
        }
      </div>
  </header>
)
  }
}

export default Header
