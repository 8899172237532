import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer id="footer">
    <div className="left">
      <div className="logo">
        <img src="/images/logo-back-black.png" />
        Conetist
      </div>
      <div>
        Edité par la société neocra <br />
        128 rue la Boétie <br />
        75008 Paris <br />
      </div>
    </div>
    <div className="middle">
        <div className="social">
          <a href="https://twitter.com/conetist_fr">
            <img src="/images/twitter.png" />
          </a>
          <a href="https://www.facebook.com/conetist">
            <img src="/images/facebook.png" />
          </a>
        </div>

        <a href="mailto:contact@conetist.com">contact@conetist.com</a>      
    </div>
    <div className="right">
      <Link  to="https://blog.conetist.com">Blog</Link>
      <Link  to="https://www.conetist.com/fr.html">Conditions générales (CGU/CGV)</Link>
    </div>
  </footer>
)

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
